import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { DSAlert, DSCheckbox } from '@demandstar/components/index';
import { DSTextInput } from '@demandstar/components/inputs';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';
import { cleanInput, displayDate, getDisplayText, getStatusBarTheme, scrollToTop, sortTagsByFrequency } from 'src/utils/helpers';
import { useRegistration } from 'src/shared/hooks/useRegistration';
import { Buttons, Loader, NoResult } from 'src/components/customcontrols';
import { Link } from 'react-router-dom';

import { useGetBidTagsCognitiveSearch } from '../../../query/queries/cognitiveSearch/getBidtagsCognitiveSearchData';
import { useGetCommodityCodesCognitiveSearch } from '../../../query/queries/cognitiveSearch/getcommoditiyCognitiveSearchData';
import { CommodityCodesSpacedButtonWrapperMobile } from '../agency-selection/styles';
import { ModalPopUp } from '../../common/modals';
import { PopUpFlexContainerMobile } from '../../../library/styles';
import { Status } from '../../../library/constants';
import { commonTags, findLegacyCommodities, mergeCommodityCodes } from '../helpers';
import { CommodityState, useCommodities, useRefreshCommodities } from '../../../shared/hooks/useCommodity';
import { Commodity } from './LegacyCommodityCodes';

export const RegistrationCommodityCodeNew = () => {
    useRefreshCommodities();
    const { commodities } = useCommodities();
    const [commMap, setCommMap] = useState<Map<string, Commodity>>(new Map());
    const [codesFromLegacy, setCodesfromLegacy] = useState<Commodity[]>();
    const registrationData = useRecoilValue<RegistrationData>(recoilRegistrationDataState);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [selectedCommoditycodes, setSelectedCommoditycodes] = useState([]);
    const [confirmationPopup, toggleConfirmationPopup] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCommodityIds, setSelectedCommodityIds] = useState([]);
    const [suggestedCodeFromSelectedTag, setSuggestedCodeFromSelectedTag] = useState([]);

    const { saveIncompleteRegistration } = useRegistration();
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const { data: bidTagsData, isFetching: isFetchingBidTags } = useGetBidTagsCognitiveSearch(searchQuery, { enabled: isSearchClicked && searchQuery.length > 0 });
    const { data: commodityCodesData, isFetching: isFetchingCommodityCodes } = useGetCommodityCodesCognitiveSearch(searchQuery, { enabled: isSearchClicked && searchQuery.length > 0 });
    const mergedCodes = mergeCommodityCodes(commodityCodesData, codesFromLegacy) as any;

    const suggestedTags = commodityCodesData?.value
        .flatMap(item => item.trimmedtags ? item.trimmedtags.split(',').map(tag => tag.trim().toLowerCase()) : [])
        .filter(tag => tag?.length > 0) || [];

    const uniqueSuggestedTags = suggestedTags
        ?.flatMap(tag => tag.toLowerCase().split(' '))
        .map(word => word.trim())
        .filter(word => word !== '')
        .filter(word => {
            const lowerCaseSelectedTags = selectedTags.map(selectedTag => selectedTag.toLowerCase().trim());
            const lowerCaseCommonTags = commonTags.map(commonTag => commonTag.toLowerCase().trim());
            return (
                !lowerCaseSelectedTags.includes(word) &&
                !lowerCaseCommonTags.some(commonTag => word.includes(commonTag))
            );
        }) || [];

    const sortedUniqueSuggestedTags = sortTagsByFrequency(uniqueSuggestedTags) || [];

    const handleCheckboxClick = (commodity, isSelected) => {
        if (isSelected) {
            setSelectedCommoditycodes(prevSelected => [...prevSelected, commodity]);
        } else {
            setSelectedCommoditycodes(prevSelected => prevSelected.filter(item => item.commodityid !== commodity.commodityid));
        }
    };


    const hasFreeAgency = () => {
        return !!registrationData.freeAgency;
    };

    const confirmSkip = () => {
        moveToNextPage([], [], [], searchQuery, false);
        toggleConfirmationPopup(false);
    };

    const handleSearch = () => {
        setIsSearchClicked(true);
        setSearchQuery(inputValue);
    };

    const ListItem = ({ label, value }: { label: string; value: any }) => (
        <li className='list-inline-item'>
            <i>{label}:</i> {value}
        </li>
    );

    const moveToNextPage = async (commodityIds: number[], selectedCommoditycodes: any[], selectedTags: any[], lastAISearch: string, commodityAddedFromLegacy: boolean) => {
        const regData = { ...registrationData, commodityIds, selectedCommoditycodes, selectedTags, lastAISearch, commodityAddedFromLegacy };
        await saveIncompleteRegistration(registrationComponent.ChooseSubscription, regData);
    };

    const goBack = async () => {
        await saveIncompleteRegistration(registrationComponent.ChooseAgency, registrationData);
    };

    const confirmContinue = () => {
        const commodityIds = selectedCommoditycodes.map((comm: any) => comm.commodityid);
        setSelectedCommodityIds(commodityIds);
        const commodityAddedFromLegacy = false;
        moveToNextPage(commodityIds, selectedCommoditycodes, selectedTags, searchQuery, commodityAddedFromLegacy);
    };

    const handleTagSelect = (tag: string) => {
        const cleanedTag = tag.replace(/\s*\(\d+\/10\)$/, '');

        setSelectedTags(prevTags => {
            const updatedTags = [...prevTags, cleanedTag];

            const suggestedCodes = commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(tag => tag.trim().toLowerCase()).includes(cleanedTag.toLowerCase()),
            ) || [];

            setSuggestedCodeFromSelectedTag(prevCodes => {
                const newCodes = [...prevCodes, ...suggestedCodes];
                return newCodes.filter((code, index, self) =>
                    index === self.findIndex(c => c.commodityid === code.commodityid),
                );
            });

            return updatedTags;
        });
    };


    const handleTagRemove = (tag: string) => {
        const updatedTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(updatedTags);


        const remainingSuggestedCodes = updatedTags.flatMap((remainingTag) => {
            return commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(t => t.trim().toLowerCase()).includes(remainingTag.toLowerCase()),
            ) || [];
        });

        const uniqueSuggestedCodes = remainingSuggestedCodes.filter((code, index, self) =>
            index === self.findIndex(c => c.commodityid === code.commodityid),
        );

        setSuggestedCodeFromSelectedTag(uniqueSuggestedCodes);
    };



    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        setSelectedCommodityIds(registrationData.commodityIds || []);
        setSelectedTags(registrationData.selectedTags || []);
        setSelectedCommoditycodes(registrationData.selectedCommoditycodes || []);
        setSearchQuery(registrationData.lastAISearch || '');
        setInputValue(registrationData.lastAISearch || '');
    }, [registrationData]);

    useEffect(() => {
        if (commodities) {
            const commoMap: Map<string, Commodity> = new Map();

            commodities.forEach((comm: CommodityState) => {
                const formattedCode = `${comm.commodityGroup}-${comm.commodityCategory}-${comm.commodityCode}`;

                commoMap.set(formattedCode, {
                    commodityCategory: comm.commodityCategory,
                    commodityCode: comm.commodityCode,
                    commodityDescription: comm.commodityDescription?.toUpperCase(),
                    commodityGroup: comm.commodityGroup,
                    commodityId: comm.commodityId,
                    formattedCode: formattedCode,
                    fullCode: formattedCode,
                    isSelected: false,
                });
            });

            setCommMap(commoMap);
            const foundCommodities = findLegacyCommodities(Array.from(commMap.values()), searchQuery);
            setCodesfromLegacy(foundCommodities);
        }
    }, [ searchQuery, commMap, commodities]);


    return (
        <>
            {isFetchingBidTags || isFetchingCommodityCodes ? <Loader loading={isFetchingBidTags || isFetchingCommodityCodes} /> :

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row'>
                        </div>
                        {hasFreeAgency() && (
                            <div className='row'>
                                <div className='col-sm-12 col-xl-9 col-md-12'>
                                    <p className='reg-intro no-top-padding '>
                                        Take control of your bid notification experience and maximize your chances of finding
                                        opportunities that are tailored to your needs. Add your key tags here and let us assist
                                        you in uncovering the most relevant and valuable bid opportunities in your industry.
                                    </p>
                                </div>
                            </div>)}
                        {!hasFreeAgency() && (
                            <div className='row'>
                                <div className='col-sm-12 col-xl-8 col-md-8'>
                                    <DSAlert
                                        header={"You haven't selected a free agency."}
                                        link={
                                            <DSLink onClick={goBack}>
                                                Please go back and select an agency for the best experience.
                                            </DSLink>
                                        }
                                        type={Status.Warning}
                                    >
                                        Without an agency selected, we can&apos;t match you up with commodity codes.
                                    </DSAlert>
                                </div>
                            </div>
                        )}
                        {hasFreeAgency() && (<>
                            <div className='row'>
                                <div className='col-sm-7 col-xl-7 col-md-7 mt-2'>
                                    <DSTextInput
                                        name={'Cognitive search bar'}
                                        value={inputValue}
                                        onChange={(value: string) => setInputValue(cleanInput(value))}
                                        placeholder={'Example : Infrastructure , Automobiles , Dairy , Agriculture '}
                                    />
                                </div>
                                <div className='col-sm-5 col-xl-5 col-md-5 '>
                                    <Buttons
                                        classNames='bttn-primary mt-1'
                                        text='Search'
                                        title='Search'
                                        type='submit'
                                        onClick={handleSearch}
                                        disable={inputValue.length < 3}
                                    />
                                </div>
                            </div>
                            {sortedUniqueSuggestedTags?.filter(tag => !selectedTags.includes(tag))?.length > 0 && (
                                <div className='row mt-2  '>
                                    <div className='col'>
                                        <h4> Suggested Tags :{' '}</h4>
                                        <div className='row tagsBlock' >
                                            {sortedUniqueSuggestedTags?.map(tag => (
                                                <div key={tag} className="search-tag mr-1 ml-1 cursorPointer" onClick={() => handleTagSelect(tag)}>
                                                    {tag}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(selectedTags.length > 0) && (
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <h4>Selected Tags: </h4>
                                        <div className='row'>
                                            {selectedTags.map(tag => (
                                                <div key={tag} className="selected-tag mr-1 ml-1 bold">
                                                    {tag}
                                                    <span
                                                        className="ml-1 remove-tag cursorPointer"
                                                        onClick={() => handleTagRemove(tag)}
                                                    >
                                                        &#10005;
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='row' >
                                <div className='col-sm-12 col-xl-7 col-md-12 mt-2'>
                                    {bidTagsData && bidTagsData?.value?.length > 0 ? (
                                        <>
                                            <h4 className='mt-1'>Relevant Bids</h4>
                                            {bidTagsData?.value.slice(0, 5).map((bid, index) => (
                                                <div
                                                    key={bid.bidid}
                                                    className={`listGroupWrapper clearfix ${index >= 3 ? 'blurred' : ''}`}
                                                >
                                                    <h5 className='mw-100 text-truncate' title={bid.title}>
                                                        <Link
                                                            className='w-75 text-truncate float-left'
                                                            to={`/limited/bids/${bid.bidid}/details`}
                                                            target='_blank'
                                                        >
                                                            {bid.title}
                                                        </Link>
                                                        <span className={getStatusBarTheme(bid.bidStatus)}>{getDisplayText(bid.bidStatus)}</span>

                                                    </h5>
                                                    <ul className='list-inline d-flex justify-content-start'>
                                                        <p>{bid.agency}</p>
                                                    </ul>
                                                    <ul className='list-inline'>
                                                        <ListItem label='ID' value={bid.BidIdentifier || ''} />
                                                        <ListItem
                                                            label='Broadcast'
                                                            value={bid.broadcastdate
                                                                ? displayDate(bid.broadCastDate, bid.tzfn) : null}
                                                        />
                                                        <ListItem
                                                            label='Due'
                                                            value={bid.duedate
                                                                ? displayDate(bid.dueDate, bid.tzfn) : null}
                                                        />
                                                    </ul>
                                                </div>
                                            ))}
                                        </>
                                    ) : isSearchClicked && !isFetchingBidTags ? (
                                        <NoResult message={'No relevant bids found based on your search criteria.'} />
                                    ) : null}
                                </div>


                                <div className='col-sm-12 col-xl-5 col-md-12'>
                                    {suggestedCodeFromSelectedTag.length > 0 && (
                                        <div className='row mt-2'>
                                            <div className='col'>
                                                <h4>Tag-Based Commodity Identification</h4>
                                                <small className='text-muted customMargin '>
                                                    (Displaying Commodity Codes Corresponding to Your Selected Tags)
                                                </small>
                                                <div className='commodityBlock'>
                                                    {suggestedCodeFromSelectedTag
                                                        .filter(commodity =>
                                                            !selectedCommoditycodes.some(
                                                                selected => selected.commodityid === commodity.commodityid,
                                                            ),
                                                        )
                                                        .map(commodity => (
                                                            <span key={commodity.commodityid}>
                                                                <div>
                                                                    <DSCheckbox
                                                                        key={commodity.commodityid}
                                                                        name='checkbox'
                                                                        label={`[${commodity.fullcode}] : ${commodity.commodity}`}
                                                                        onClick={() => handleCheckboxClick(commodity, true)}
                                                                        checked={false}
                                                                    />
                                                                </div>
                                                            </span>
                                                        ))}
                                                    {suggestedCodeFromSelectedTag.length > 0 && suggestedCodeFromSelectedTag
                                                        .filter(commodity =>
                                                            !selectedCommoditycodes.some(
                                                                selected => selected.commodityid === commodity.commodityid,
                                                            ),
                                                        )
                                                        .length === 0 ?
                                                        <NoResult message='Select a New tag to see more results ' /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {mergedCodes
                                        .filter(
                                            (commodity) =>
                                                !selectedCommoditycodes.some(
                                                    (selected) => selected.commodityid === commodity.commodityid,
                                                ),
                                        ).length > 0 ? (
                                        <>
                                            <h4 className='mt-3'>Relevant Commodity Codes</h4>
                                            <div className='relevantCommodityBlock'>
                                                {mergedCodes
                                                    .filter(
                                                        (commodity) =>
                                                            !selectedCommoditycodes.some(
                                                                (selected) => selected.commodityid === commodity.commodityid,
                                                            ),
                                                    )
                                                    .map((commodity) => (
                                                        <span key={commodity.commodityid}>
                                                            <div>
                                                                <DSCheckbox
                                                                    key={commodity.commodityid}
                                                                    name='checkbox'
                                                                    label={`[${commodity.fullcode}] : ${commodity.commodity}`}
                                                                    onClick={() => handleCheckboxClick(commodity, true)}
                                                                    checked={selectedCommoditycodes.some(
                                                                        (selected) => selected.commodityid === commodity.commodityid,
                                                                    )}
                                                                />
                                                            </div>
                                                        </span>
                                                    ))}
                                            </div>
                                        </>
                                    ) : isSearchClicked && !isFetchingCommodityCodes ? (<>
                                        <h4 className='mt-3'>Relevant Commodity Codes</h4>
                                        <NoResult message='No relevant commodity codes. Please make a new search' />
                                    </>
                                    ) : null}

                                    {(selectedCommoditycodes.length > 0) && (
                                        <>
                                            <h4 className='mt-3'>Selected Commodity Codes {'(' + selectedCommoditycodes.length + ')'}</h4>
                                            <div className='commodityBlock'>
                                                {selectedCommoditycodes.map((commodity) => (
                                                    <span key={commodity.commodityid}>
                                                        <div >
                                                            <DSCheckbox
                                                                key={commodity.commodityid}
                                                                name='checkbox'
                                                                label={`
                                                                  [${commodity.fullcode}] : 
                                                                 ${commodity.commodity}`}
                                                                onClick={() => handleCheckboxClick(commodity, false)}
                                                                checked
                                                            />
                                                        </div>
                                                    </span>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>)}
                        <div className='row pt-3 mt-3'>
                            <div className='col-sm-12 col-xl-8 col-md-12 mr-2 '>
                                <span>
                                    <DSButton type='secondary' onClick={goBack}>
                                        Go Back
                                    </DSButton>
                                </span>
                                <span className='bttn-continue'>
                                    <CommodityCodesSpacedButtonWrapperMobile>
                                        {hasFreeAgency() && (
                                            <span className='mr-3'>
                                                <DSLink onClick={() => toggleConfirmationPopup(true)}>
                                                    Skip Commodity Codes and Tags
                                                </DSLink>
                                            </span>
                                        )}
                                        <DSButton type='primary' onClick={confirmContinue}>
                                            Continue
                                        </DSButton>
                                    </CommodityCodesSpacedButtonWrapperMobile>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            }
            <ModalPopUp
                size='lg'
                title='Are you sure?'
                closeModal={() => toggleConfirmationPopup(false)}
                isOpen={confirmationPopup === true}
                backdrop='static'
            >
                <p>
                    To get notifications about bids that may be a good fit for your business, you must choose
                    commodity codes. Adding codes is free!
                </p>
                <div className='pt-3 mt-3'>
                    <PopUpFlexContainerMobile justifyContent='space-between'>
                        <DSButton type='secondary' onClick={confirmSkip}>
                            Skip adding commodity codes and Tags
                        </DSButton>
                        <span className='bttn-continue'>
                            <DSButton type='primary' onClick={() => toggleConfirmationPopup(false)}>
                                Add commodity codes and Tags
                            </DSButton>
                        </span>
                    </PopUpFlexContainerMobile>
                </div>
            </ModalPopUp>


        </>
    );
};
