export { capitalize } from '@demandstar/components/utils';

export function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) {
    return singular;
  } else {
    return plural;
  }
}
function removeSqlKeywords(inputString) {
  const sqlKeywords = [
    'SELECT', 'INSERT', 'UPDATE', 'DELETE', 'DROP', 'ALTER', 'TRUNCATE', 
    'EXEC', 'UNION', 'OR', 'AND', 'WHERE', 'FROM', 'GRANT', 'REVOKE', 
    '--', 'LOAD_FILE', 'INTO OUTFILE', 'INFORMATION_SCHEMA', 'SYSTEM', 'XP_CMDSHELL','LOADFILE',  'INFORMATIONSCHEMA' , 'XPCMDSHELL','SCRIPT',
  ];

  const keywordPattern = new RegExp(`\\b(${sqlKeywords.join('|')})\\b`, 'gi');
  inputString = inputString.replace(keywordPattern, '');

  return inputString.trim();
}


export const cleanInput = (input) => {
  let cleanedInput = input.replace(/[^a-zA-Z0-9&, ]/g, '');
  cleanedInput = cleanedInput.replace(/script/gi, '');
  cleanedInput = removeSqlKeywords(cleanedInput);
  return cleanedInput;
};
