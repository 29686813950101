import { useEffect, useState } from 'react';
import { DSCheckbox, DSTextInput } from '../../../../../library';
import { Buttons, Loader, NoResult } from '../../../../customcontrols';
import { useGetBidTagsCognitiveSearch } from '../../../../../query/queries/cognitiveSearch/getBidtagsCognitiveSearchData';
import { useGetCommodityCodesCognitiveSearch } from '../../../../../query/queries/cognitiveSearch/getcommoditiyCognitiveSearchData';
import { cleanInput, displayDate, getDisplayText, getStatusBarTheme, sortTagsByFrequency } from '../../../../../utils/helpers';
import { useGetAccountInfoCommodity } from '../../../../../query/queries/getAccountInfoCommodity';
import { postUpdateCommodityCodes } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { commonTags, findLegacyCommodities, mergeCommodityCodes } from '../../../../products/helpers';
import { CommodityState, useCommodities, useRefreshCommodities } from '../../../../../shared/hooks/useCommodity';
import { Commodity } from '../../../../products/commodity-code-AI/LegacyCommodityCodes';

export const EditCommodityCodesAndTags = ({ setShowEditcommodityPopUp }) => {
    useRefreshCommodities();
    const { commodities } = useCommodities();
    const [commMap, setCommMap] = useState<Map<string, Commodity>>(new Map());
    const [codesFromLegacy, setCodesfromLegacy] = useState<Commodity[]>();
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCommoditycodes, setSelectedCommoditycodes] = useState([]);
    const { data: existingCommodityAndTags, isFetching: existingdataFetching } = useGetAccountInfoCommodity();
    const existingTags = existingCommodityAndTags?.data?.result?.tags.split(',')?.map(tag => tag.trim()) || [];
    const { data: bidTagsData, isFetching: isFetchingBidTags } = useGetBidTagsCognitiveSearch(searchQuery, { enabled: isSearchClicked && searchQuery.length > 0 });
    const { data: commodityCodesData, isFetching: isFetchingCommodityCodes } = useGetCommodityCodesCognitiveSearch(searchQuery, { enabled: isSearchClicked && searchQuery.length > 0 });
    const [suggestedCodeFromSelectedTag, setSuggestedCodeFromSelectedTag] = useState([]);
    const mergedCodes = mergeCommodityCodes(commodityCodesData, codesFromLegacy) as any;
    const suggestedTags = commodityCodesData?.value
        .flatMap(item => item.trimmedtags ? item.trimmedtags.split(',').map(tag => tag.trim().toLowerCase()) : [])
        .filter(tag => tag?.length > 0) || [];

    const uniqueSuggestedTags = suggestedTags
        ?.flatMap(tag => tag.toLowerCase().split(' '))
        .map(word => word.trim())
        .filter(word => word !== '')
        .filter(word => {
            const lowerCaseSelectedTags = selectedTags.map(selectedTag => selectedTag.toLowerCase().trim());
            const lowerCaseCommonTags = commonTags.map(commonTag => commonTag.toLowerCase().trim());

            return (
                !lowerCaseSelectedTags.includes(word) &&
                !lowerCaseCommonTags.some(commonTag => word.includes(commonTag))
            );
        }) || [];

    const sortedUniqueSuggestedTags = sortTagsByFrequency(uniqueSuggestedTags) || [];
    const dispatch = useDispatch();

    const handleAddtagsCommoditiesClick = async () => {
        const commodityIds = selectedCommoditycodes?.map((comm: any) => comm.commodityid);
        const combinedTags = [...new Set([...(existingTags || []), ...(selectedTags || [])])];

        if (commodityIds?.length || selectedTags?.length) {
            dispatch(postUpdateCommodityCodes({ data: { commodityIds, combinedTags } }));
            setShowEditcommodityPopUp();
        }
    };

    const handleSearch = () => {
        setIsSearchClicked(true);
        setSearchQuery(inputValue);
    };


    const handleTagSelect = (tag: string) => {
        const cleanedTag = tag.replace(/\s*\(\d+\/10\)$/, '');

        setSelectedTags(prevTags => {
            const updatedTags = [...prevTags, cleanedTag];

            const suggestedCodes = commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(tag => tag.trim().toLowerCase()).includes(cleanedTag.toLowerCase()),
            ) || [];

            setSuggestedCodeFromSelectedTag(prevCodes => {
                const newCodes = [...prevCodes, ...suggestedCodes];
                return newCodes.filter((code, index, self) =>
                    index === self.findIndex(c => c.commodityid === code.commodityid),
                );
            });

            return updatedTags;
        });
    };


    const handleTagRemove = (tag: string) => {
        const updatedTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(updatedTags);


        const remainingSuggestedCodes = updatedTags.flatMap((remainingTag) => {
            return commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(t => t.trim().toLowerCase()).includes(remainingTag.toLowerCase()),
            ) || [];
        });

        const uniqueSuggestedCodes = remainingSuggestedCodes.filter((code, index, self) =>
            index === self.findIndex(c => c.commodityid === code.commodityid),
        );

        setSuggestedCodeFromSelectedTag(uniqueSuggestedCodes);
    };

    const handleCheckboxClick = (commodity, isSelected) => {
        if (isSelected) {
            setSelectedCommoditycodes(prevSelected => [...prevSelected, commodity]);
        } else {
            setSelectedCommoditycodes(prevSelected => prevSelected.filter(item => item.commodityid !== commodity.commodityid));
        }
    };

    const ListItem = ({ label, value }: { label: string; value: any }) => (
        <li className='list-inline-item'>
            <i>{label}:</i> {value}
        </li>
    );
    useEffect(() => {
        if (commodities) {
            const commoMap: Map<string, Commodity> = new Map();

            commodities.forEach((comm: CommodityState) => {
                const formattedCode = `${comm.commodityGroup}-${comm.commodityCategory}-${comm.commodityCode}`;

                commoMap.set(formattedCode, {
                    commodityCategory: comm.commodityCategory,
                    commodityCode: comm.commodityCode,
                    commodityDescription: comm.commodityDescription?.toUpperCase(),
                    commodityGroup: comm.commodityGroup,
                    commodityId: comm.commodityId,
                    formattedCode: formattedCode,
                    fullCode: formattedCode,
                    isSelected: false,
                });
            });

            setCommMap(commoMap);
            const foundCommodities = findLegacyCommodities(Array.from(commMap.values()), searchQuery);
            setCodesfromLegacy(foundCommodities);
        }
    }, [ searchQuery, commMap, commodities]);


    return (
        <><Loader loading={isFetchingBidTags || isFetchingCommodityCodes || existingdataFetching} />
            <div>
                <p>
                    To get notifications about bids that may be a good fit for your business, you must choose
                    commodity codes. Adding codes is free!
                </p>
                <p className='reg-intro no-top-padding '>
                    Take control of your bid notification experience and maximize your chances of finding
                    opportunities that are tailored to your needs. Add your key tags here and let us assist
                    you in uncovering the most relevant and valuable bid opportunities in your industry.
                </p>
                <p>For example if you need notifications of bids related to Automobiles. search with keywords 'Automobiles' / 'vehicles' we will show related tags and commodity codes that may suit your need</p>
            </div>
            <div className='row'>
                <div className='col col-7 mt-2'>
                    <DSTextInput
                        name={'Cognitive search bar'}
                        value={inputValue}
                        onChange={(value: string) => setInputValue(cleanInput(value))}
                        placeholder={'Example : Infrastructure , Automobiles , Dairy , Agriculture '}
                    />
                </div>
                <div className='col col-5'>
                    <Buttons
                        classNames='bttn-primary mt-1'
                        text='Search'
                        title='Search'
                        type='submit'
                        onClick={handleSearch}
                        disable={inputValue.length<3}
                    />
                </div>
            </div>
            <div className="col-12">

                {suggestedTags?.filter(tag => !selectedTags.includes(tag))?.length > 0 && (
                    <div className='row mt-2  '>
                        <div className='col'>
                            <h4> Suggested Tags :</h4>
                            <div className='row tagsBlock' >
                                {sortedUniqueSuggestedTags?.filter(tag => !selectedTags.includes(tag)).map(tag => (
                                    <div key={tag} className="search-tag mr-1 ml-1 cursorPointer" onClick={() => handleTagSelect(tag)}>
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {(selectedTags?.length > 0) && (
                    <div className='row mt-2'>
                        <div className='col'>
                            <h4>Selected Tags: </h4>
                            <div className='row'>
                                {selectedTags?.map(tag => (
                                    <div key={tag} className="selected-tag mr-1 ml-1 bold">
                                        {tag}
                                        <span
                                            className="ml-1 remove-tag cursorPointer"
                                            onClick={() => handleTagRemove(tag)}
                                        >
                                            &#10005;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <div className="row">
                <div className='col col-7 mt-2'>
                    {bidTagsData && bidTagsData?.value?.length > 0 ? (
                        <>
                            <h4 className='mt-1'>Relevant Bids</h4>
                            {bidTagsData?.value.slice(0, 5).map((bid, index) => (
                                <div
                                    key={bid.bidid}
                                    className={`listGroupWrapper clearfix ${index >= 3 ? 'blurred' : ''}`}
                                >
                                    <h5 className='mw-100 text-truncate' title={bid.title}>
                                        <a
                                            className='w-75 text-truncate float-left cursorPointer'


                                        >
                                            {bid.title}
                                        </a>
                                        <span className={getStatusBarTheme(bid.bidStatus)}>{getDisplayText(bid.bidStatus)}</span>

                                    </h5>
                                    <ul className='list-inline d-flex justify-content-start'>
                                        <p>{bid.agency}</p>
                                    </ul>
                                    <ul className='list-inline'>
                                        <ListItem label='ID' value={bid.BidIdentifier || ''} />
                                        <ListItem
                                            label='Broadcast'
                                            value={bid.broadcastdate
                                                ? displayDate(bid.broadCastDate, bid.tzfn) : null}
                                        />
                                        <ListItem
                                            label='Due'
                                            value={bid.duedate
                                                ? displayDate(bid.dueDate, bid.tzfn) : null}
                                        />
                                    </ul>
                                </div>
                            ))}
                        </>
                    ) : isSearchClicked && !isFetchingBidTags ? (
                        <NoResult message={'No relevant bids found based on your search criteria.'} />
                    ) : null}
                </div>
                <div className='col col-5 '>
                    {suggestedCodeFromSelectedTag.length > 0 && (
                        <div className='row mt-2'>
                            <div className='col'>
                                <h4>Commodity Codes from Selected Tags</h4>
                                <small className='text-muted customMargin '>
                                                    (Displaying Commodity Codes Corresponding to Your Selected Tags)
                                                </small>
                                <div className='commodityBlock'>
                                    {suggestedCodeFromSelectedTag
                                        .filter(commodity =>
                                            !selectedCommoditycodes.some(
                                                selected => selected.commodityid === commodity.commodityid,
                                            ),
                                        )
                                        .map(commodity => (
                                            <span key={commodity.commodityid}>
                                                <div>
                                                    <DSCheckbox
                                                        key={commodity.commodityid}
                                                        name='checkbox'
                                                        label={`[${commodity.fullcode}] : ${commodity.commodity}`}
                                                        onClick={() => handleCheckboxClick(commodity, true)}
                                                        checked={false}
                                                    />
                                                </div>
                                            </span>

                                        ))}
                                    {suggestedCodeFromSelectedTag.length > 0 && suggestedCodeFromSelectedTag
                                        .filter(commodity =>
                                            !selectedCommoditycodes.some(
                                                selected => selected.commodityid === commodity.commodityid,
                                            ),
                                        )
                                        .length === 0 ?
                                        <NoResult message='Select a New tag to see more results ' /> : null}
                                </div>
                            </div>
                        </div>
                    )}

                    {mergedCodes
                        .filter(
                            (commodity) =>
                                !selectedCommoditycodes.some(
                                    (selected) => selected.commodityid === commodity.commodityid,
                                ),
                        ).length > 0 ? (
                        <>
                            <h4 className='mt-3'>Relevant Commodity Codes</h4>
                            <div className='relevantCommodityBlock'>
                                {mergedCodes
                                    .filter(
                                        (commodity) =>
                                            !selectedCommoditycodes.some(
                                                (selected) => selected.commodityid === commodity.commodityid,
                                            ),
                                    )
                                    .map((commodity) => (
                                        <span key={commodity.commodityid}>
                                            <div>
                                                <DSCheckbox
                                                    key={commodity.commodityid}
                                                    name='checkbox'
                                                    label={`[${commodity.fullcode}] : ${commodity.commodity}`}
                                                    onClick={() => handleCheckboxClick(commodity, true)}
                                                    checked={selectedCommoditycodes.some(
                                                        (selected) => selected.commodityid === commodity.commodityid,
                                                    )}
                                                />
                                            </div>
                                        </span>
                                    ))}
                            </div>
                        </>
                    ) : isSearchClicked ? (<>
                        <h4 className='mt-3'>Relevant Commodity Codes</h4>
                        <NoResult message='No relevant commodity codes. Please make a new search' />
                    </>
                    ) : null}

                    {(selectedCommoditycodes.length > 0) && (
                        <>
                            <h4 className='mt-3'>Selected Commodity Codes {'(' + selectedCommoditycodes.length + ')'}</h4>
                            <div className='commodityBlock'>
                                {selectedCommoditycodes.map((commodity) => (
                                    <span key={commodity.commodityid}>
                                        <div >
                                            <DSCheckbox
                                                key={commodity.commodityid}
                                                name='checkbox'
                                                label={`
                          [${commodity.fullcode}] : 
                         ${commodity.commodity}`}
                                                onClick={() => handleCheckboxClick(commodity, false)}
                                                checked
                                            />
                                        </div>
                                    </span>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex ">
                    <Buttons
                        classNames='bttn-secondary mt-3 ml-5'
                        text='Not Now'
                        title='Not now '
                        type='button'
                        onClick={() => setShowEditcommodityPopUp(false)}
                    />
                </div>
                <div className="d-flex ">
                    <Buttons
                        classNames='bttn-primary mt-3 mr-5 '
                        text='Add Tags and Commodity Codes'
                        title='Add Tags and Commodity Codes '
                        type='submit'
                        onClick={handleAddtagsCommoditiesClick}
                        disable={selectedCommoditycodes.length === 0 && selectedTags.length === 0}
                    />
                </div>
            </div>

        </>
    );
};
